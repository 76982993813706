import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
	componentDidMount() {
		const { change_nav } = this.props,
			{ pathname } = window.location,
			other = document.querySelectorAll(".slim-navbar .nav-item a");

		other.forEach((element) => {
			let data_nav = element.getAttribute("data-nav");
			if (element.href.indexOf(pathname) > -1) {
				change_nav(parseInt(data_nav));
			}
		});
	}

	handleActive = (e) => {
		const data_nav = e.currentTarget.getAttribute("data-nav"),
			{ change_nav } = this.props;

		change_nav(parseInt(data_nav));
	};

	render() {
		const { active } = this.props;

		return (
			<div className="slim-navbar">
				<div className="container">
					<ul className="nav">
						<li className={`nav-item ${active === 0 && "active"}`}>
							<Link onClick={this.handleActive} data-nav={0} to="/dashboard" className="nav-link">
								<i className="icon ion-ios-home-outline"></i>
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={`nav-item ${active === 1 && "active"}`}>
							<Link onClick={this.handleActive} data-nav={1} to="/kpi" className="nav-link">
								<i className="icon ion-ios-people-outline"></i>
								<span>KPI</span>
							</Link>
						</li>
						<li className={`nav-item with-sub ${active === 2 && "active"}`}>
							<Link
								onClick={this.handleActive}
								data-nav={2}
								to="/ticket/all"
								className="nav-link"
								data-toggle="dropdown"
							>
								<i className="icon ion-ios-pricetags-outline"></i>
								<span>Tickets</span>
							</Link>
							<div className="sub-item">
								<ul>
									<li>
										<Link onClick={this.handleActive} data-nav={2} to="/ticket/all">
											<span>Ticket List</span>
										</Link>
										<Link onClick={this.handleActive} data-nav={2} to="/ticket/bulk_reply">
											<span>
												Bulk Reply{" "}
												<span className="badge badge-pill badge-primary tx-8 tx-bold">
													BETA
												</span>
											</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className={`nav-item with-sub ${active === 3 && "active"}`}>
							<Link
								onClick={this.handleActive}
								data-nav={3}
								to="/ticket"
								className="nav-link"
								data-toggle="dropdown"
							>
								<i className="icon ion-ios-cog-outline"></i>
								<span>Admins</span>
							</Link>
							<div className="sub-item">
								<ul>
									<li>
										<a
											href="https://supportadm.garena.co.id/admin/"
											target="_blank"
											rel="noopener noreferrer"
										>
											SUPPORT
										</a>
									</li>
									<li>
										<a
											href={`${process.env.REACT_APP_API_ENDPOINT}admin/?token=${window.localStorage.token}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											OTRS
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default Navbar;
