import React, { Component } from "react";
import styles from "./scss/Login.module.scss";

class Login extends Component {
	handleClick = () => {
		window.location.href = process.env.REACT_APP_API_ENDPOINT + "otrs/login/";
	};

	render() {
		return (
			<div className={`signin-wrapper ${styles.wrapper}`}>
				<div className="signin-box">
					<h2 className="slim-logo">
						<a href="index.html">
							slim<span>.</span>
						</a>
					</h2>
					<h2 className="signin-title-primary">Welcome back!</h2>
					<h3 className="signin-title-secondary">Sign in to continue.</h3>
					{/* <button className="btn btn-primary btn-block btn-signin" onClick={this.handleClick}>Sign In</button> */}
					<div
						id="g_id_onload"
						data-client_id="192524831127-km1t2jqskt7lnfiqqddootcknbbovsub.apps.googleusercontent.com"
						data-context="signin"
						data-ux_mode="redirect"
						data-login_uri={`${process.env.REACT_APP_API_ENDPOINT}otrs/callback/`}
						data-auto_prompt="false"
					></div>

					<div
						className="g_id_signin"
						data-type="standard"
						data-shape="rectangular"
						data-theme="outline"
						data-text="continue_with"
						data-size="large"
						data-logo_alignment="left"
					></div>
				</div>
			</div>
		);
	}
}

export default Login;
